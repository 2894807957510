import React, { useEffect, useState } from 'react';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

const stripePromise = loadStripe('pk_live_WktOUZrZVSjcegfNOqziOCC300nUaGVOOI');

const InitialState = {
    cartIsOpen: false,
    cartCount: 0,
    cartTotal: 0,
    cartItems: new Map(),    
    toggleCart: () => {},
    addToCart: (product) => {},
    removeAllProductFromCart: (productName) => {},
    decreaseQuantityOfProductFromCart: (productName) => {},
    increaseQuantityOfProductFromCart: (productName) => {}
}

export const ManageState = () => {
    const [cartCount, setCartCount] = useState(InitialState.cartCount);
    const [cartIsOpen, setCartIsOpen] = useState(InitialState.cartIsOpen);
    const [cartItems, setCartItems] = useState(InitialState.cartItems);
    const [cartTotal, setCartTotal] = useState(InitialState.cartTotal);

    useEffect(() => {
        console.log(`Calculating Cart Total`);
        let total = 0;

        cartItems.forEach((v,k)=> {
            const subTotal = v.product.price * v.quantity;
            total += subTotal;
        });

        console.log(`Total is ${total}`);
        setCartTotal(total);

    }, [cartCount, cartItems])

    const removeAllProductFromCart = (productName) => {
        console.log(`Removing ${productName} from cart`);
        if(cartItems.has(productName)){      
            const quantity = cartItems.get(productName).quantity;                   
            cartItems.delete(productName);               
            setCartItems(cartItems);   
            setCartCount(cartCount - quantity);     
            console.log(`Removed ${productName} from cart`);
        }
    }

    const decreaseQuantityOfProductFromCart = (productName) => {
        console.log(`Removing 1 of ${productName} from cart`);
        if(cartItems.has(productName)){
            let current = cartItems.get(productName);
            if(current.quantity === 1)
                removeAllProductFromCart(productName);
            else{
                current.quantity = current.quantity - 1;            
                cartItems.set(productName, current)            
                console.log(`Removed 1 of ${productName} from cart`);
                setCartItems(cartItems);
                setCartCount(cartCount - 1);        
            }
            
            console.log(`Removed 1 of ${productName} from cart`);
        }
    }

    const increaseQuantityOfProductFromCart = (productName) => {
        console.log(`Adding 1 of ${productName} from cart`);
        if(cartItems.has(productName)){
            let current = cartItems.get(productName);            
            current.quantity = current.quantity + 1;            
            cartItems.set(productName, current)            
            console.log(`Added 1 of ${productName} from cart`);
            setCartItems(cartItems);
            setCartCount(cartCount + 1);        
        }
    }

    const addProductToCart = (product, quantity) => {
        console.log(`Adding ${quantity} of ${product.name} to cart`);
        console.log(product);
        setCartCount(cartCount + quantity);        

        if(cartItems.has(product.name)){
            let current = cartItems.get(product.name);
            current.quantity = current.quantity + quantity;
            cartItems.set(product.name, current)
        }
        else{
            console.log(`Adding New Item`);
            cartItems.set(product.name, {product, quantity});
            let current = cartItems.get(product.name);
            console.log(current);
        }
        
        cartItems.forEach((v,k)=> {
            console.log('Logging Cart Item');
            console.log(k);
            console.log(v);            
        })
        
        setCartItems(cartItems);
        if(cartCount === 0 && !cartIsOpen){
            setCartIsOpen(true);
        }
    }
    
    return {cartItems, cartCount, cartIsOpen, cartTotal, toggleCart: () => {

        //Trying to debug
        console.log(`updating cart is open, current state is ${cartIsOpen}`);
        setCartIsOpen(!cartIsOpen);
        console.log(`updated cart is open, current state is ${cartIsOpen}`);

    }, addToCart: (product, quantity) => addProductToCart(product, quantity),
    removeAllProductFromCart, decreaseQuantityOfProductFromCart, increaseQuantityOfProductFromCart}; 
}

export const CartContext = React.createContext(InitialState);

export function CartProvider(props){

    return (
    <CartContext.Provider value={ManageState()}>
        <Elements stripe={stripePromise}>
            {props.children}
        </Elements>
    </CartContext.Provider>
    )

}