// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-blog-blog-post-page-js": () => import("./../../../src/components/Blog/blogPostPage.js" /* webpackChunkName: "component---src-components-blog-blog-post-page-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-join-us-js": () => import("./../../../src/pages/joinUs.js" /* webpackChunkName: "component---src-pages-join-us-js" */),
  "component---src-pages-oils-lab-js": () => import("./../../../src/pages/oilsLab.js" /* webpackChunkName: "component---src-pages-oils-lab-js" */),
  "component---src-pages-order-complete-js": () => import("./../../../src/pages/orderComplete.js" /* webpackChunkName: "component---src-pages-order-complete-js" */),
  "component---src-pages-product-details-js": () => import("./../../../src/pages/productDetails.js" /* webpackChunkName: "component---src-pages-product-details-js" */),
  "component---src-pages-recipies-js": () => import("./../../../src/pages/recipies.js" /* webpackChunkName: "component---src-pages-recipies-js" */),
  "component---src-pages-shop-js": () => import("./../../../src/pages/shop.js" /* webpackChunkName: "component---src-pages-shop-js" */),
  "component---src-pages-store-down-js": () => import("./../../../src/pages/storeDown.js" /* webpackChunkName: "component---src-pages-store-down-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-pages-workshop-js": () => import("./../../../src/pages/workshop.js" /* webpackChunkName: "component---src-pages-workshop-js" */)
}

